// axiosInstance.js
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://ecom-new-uvjj.onrender.com/",
});

export default axiosInstance;

export const weburl = "https://ecom-new-uvjj.onrender.com/";
export const shopurl = "https://shopadmin.tutiongo.com/";
export const mainurl = "https://shopadmin.tutiongo.com/";
